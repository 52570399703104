import styled from 'styled-components'
import {
  xl,
  lg,
  md,
  sm,
  sh,
  themeColor,
  black,
  metaFont,
  metaRegFont,
} from 'config/variables'

export const CulturecraftedsecStyles = styled.section`
  background-color: #fafbff;
  padding: 110px 0;
  ${sh(`
    padding: 90px 0; 
	`)}
  ${xl(`
    padding: 80px 0; 
  `)}
  ${lg(`
    padding: 60px 0; 
  `)}
  ${md(`
    padding: 40px 0; 
  `)} 
  .cultureCraftedInner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    ${lg(`
      flex-direction: column-reverse;
    `)} 
    .cultureCraftedThumb {
      flex: 0 1 581px;
      padding: 0 30px 30px 15px;
      position: relative;
      ${lg(`
        flex: 1 1 auto 
        width: 100%;
        max-width: 470px;
        margin: 0 auto;
        min-height:550px;
      `)} 
      ${sm(`
        flex: 1 1 auto 
        width: 100%;
        max-width: 470px;
        margin: 0 auto; 
        padding: 0 0;
      `)} 
    }
    .bg-image {
      ${sm(`
        display: none;
      `)} 
    }
    .rating-card {
      position: absolute;
      top: 42px;
      right: 0;
      border-radius: 6px;
      box-shadow: 2px 3px 10px rgba(0,0,0, 0.10464); 
      background-color: rgba(255,255,255, 0.94);
      padding: 28px 30px;
      width: 336px;
      z-index:2;
      ${sm(`
        position: relative;
        width: 100%;
        top: 0;
        right: 0;
        margin-bottom:30px;
      `)} 
      &.glassdoor-card {
        top: inherit;
        left: 0;
        right: inherit;
        bottom: 0;
        width: 390px;
        ${sm(`
          position: relative;
          width: 100%;
          top: 0;
          right: 0;
          left: 0;
          margin-bottom:0;
        `)}
        .rating-card-logo {
          position: absolute;
          top: -18px;
          left: auto;
          right: -50px;
          ${sm(`
            top: -25px;
            right: -10px;
          `)}
        }
      }
      h4 {
        margin-bottom: 12px;
        opacity: 1;
        color: #252D38; 
        font-size: 15px;
        line-height: 20px;
        font-weight: 700; 
        letter-spacing: -0.09px; 
        text-transform: uppercase;
      }
      .rating-card-logo {
        position: absolute;
        top: -18px;
        left: -48px; 
        ${sm(`
          left: -10px;
          top: -48px;
        `)}
      }
      .review-item-row {
        display: flex;
        flex-direction: column;
        margin-bottom: 12px;
        &:last-child {
          margin-bottom: 0;
        }
        .review-text {
          opacity: 1;
          color: rgba(84,100,122,1); 
          font-size: 14px;
          line-height: 20px;
          font-weight: 600; 
          letter-spacing: -0.09px;
          font-family: ${metaFont};
          margin: 0 0 6px;
        }
        .designation-text { 
          color: #54647A;
          font-family: ${metaRegFont};
          font-size: 12px;
          line-height: 17px;
          font-weight: 400;
          font-style: italic;
          letter-spacing: -0.08px;
          text-align: left;
          margin: 0;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          .rating-group {
            margin-left: 8px;
            display: flex;
            align-items: center;
            .star-icon {
              margin: 0 1px;
              display: flex;
            }
          }
        }
      }
    }
    .cultureCraftedContent {
      flex: 0 1 582px;
      padding-left: 70px;
      ${xl(`
        padding-left: 50px;
      `)}
      ${lg(`
        padding-left: 0;
        flex: 1 1 auto
        padding-bottom:70px;
      `)}
      h2 {
        margin: 0 0 30px;
      }
      p {
        font-size: 21px;
        line-height: 1.55;
        letter-spacing: -0.14px;
        margin: 0 0 50px;
        ${sh(`
          font-size: 20px;
          line-height: 26px;
          margin-bottom: 27px;
        `)}
        ${md(`
          font-size: 18px;
          line-height: 24px;
          margin-bottom: 20px;
          margin: 0 0 20px;
        `)}
      }
      .btn-read {
        font-size: 18px;
        line-height: 24px;
        margin-top: 23px;
        font-weight: 500;
        position: relative; 
        color: ${themeColor};
        transition: all 0.5s ease;
        display: inline-block;
        .line {
          width: 60px;
          height: 3px;
          background: #EF5366;
          border-radius: 1px;
          display: block;
          -webkit-transition: all 0.5s ease;
          transition: all 0.5s ease;
          position: absolute;
          top: -20px;
          &::before {
            content: '';
            height: 100%;
            background: #000;
            position: absolute;
            top: 0;
            left: 0;
            width: 0;
            -webkit-transition: all 0.5s ease;
            transition: all 0.5s ease;
          }
        } 
        &:hover {
          color: ${black};
          .line {
            &::before {
              width:100%;
            }
          }
        }
      }
    }
  }
`
