import Picture from 'components/Picture/Picture'
import { images } from 'config/images'
import React from 'react'
import {
  NewcasestudiescardStyles,
  MoreCaseStudiesCardStyle,
} from './Newcasestudiescard.styles'

function Newcasestudiescard(props) {
  return (
    <div>
      {!props.moreCaseStudiesCard ? (
        <NewcasestudiescardStyles className={props.className}>
          <div className="logo">
            <img src={props.caseLogo2x} alt="" />
          </div>
          <div className="case-info">
            <h3>{props.title}</h3>
            <p>{props.info}</p>
            <p className="btn-read">
              <span className="line" />
              Read more
            </p>
          </div>
          <div className="case-result-info">
            <h4>Results</h4>
            <div className="case-result-row">
              {props.resultInfo.map((result, i) => {
                return (
                  <div key={`result${i}`} className="case-result-col">
                    <h5>
                      {result.resultTitle} <span>{result.info}</span>
                    </h5>
                  </div>
                )
              })}
            </div>
          </div>
        </NewcasestudiescardStyles>
      ) : (
        <MoreCaseStudiesCardStyle>
          <div className="left-logo-col">
            {props.logoItems.map((data, i) => {
              return (
                <div key={i} className="logo-item">
                  <img src={data.caseLogo2x} alt="" />
                </div>
              )
            })}
          </div>
          <div className="text-right-col">
            <p>And many more</p>
          </div>
        </MoreCaseStudiesCardStyle>
      )}
    </div>
  )
}

export default Newcasestudiescard
