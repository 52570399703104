import styled from 'styled-components'
import { lg, md, sh, white, xl, primaryColor } from 'config/variables'
import { images } from 'config/images'

export const OutsourcingPartnerStyle = styled.section` 
  position: relative; 
  .outsourcing-head {   
    margin-bottom: 62px;
    ${xl(`
      margin-bottom: 50px;
    `)}
    ${lg(`
      margin-bottom: 40px;
    `)}
    ${md(`
      margin-bottom: 30px;
    `)}
    p {
      font-size: 21px;
      letter-spacing: -0.15px;
      line-height: 28px;
      margin-bottom: 30px;
      ${sh(`
        font-size: 20px;
        line-height: 26px;
        margin-bottom: 27px;
      `)}
      ${md(`
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 20px;
      `)}
      br{
        ${md(`
          display: none;
        `)}
      }
    }   
  } 
  .outsourcing-card-link {
    margin-bottom: 20px; 
    display: block;
    padding: 26px 30px 26px 35px; 
    border-radius: 3px;
    box-shadow: 0px 0px 10px rgba(0,0,0, 0.062077); 
    background-color: ${white}; 
    color: ${primaryColor};
    transition: all 0.3s ease-in-out;
    ${md(` 
      padding: 15px; 
    `)}
    &:last-child {
      margin-bottom: 0;
    }
    &:hover { 
      box-shadow: 0px 6px 30px rgba(0,0,0, 0.091942); 
      .outsourcing-card-info {
        &::after { 
          transform: translateX(5px);
        }
      }
    }
  }
  .outsourcing-content {
    .outsourcing-card {
      width: 100%;  
      display: flex;
      ${md(` 
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
      `)}
      .outsourcing-card-head {
        display: flex;
        align-items: center;
        flex: 0 0 266px;
        width: 266px;
        max-width: 266px;
        border-right: hidden;
        padding-right: 20px;
        border-right: 1px solid rgba(0,0,0, 0.08); 
        ${lg(`
           flex: 0 0 170px;
           width: 170px;
           max-width: 170px;
           flex-direction: column;
           align-items: flex-start;
           justify-content: center;
        `)}
        ${md(` 
           border-right: 0; 
           flex: 1 1 auto;
           width: 100%;
           max-width: 100%;
        `)}
        .outsourcing-icon { 
          flex: 0 0 50px;
          width: 50px;
          max-width: 50px; 
        }
        h5 {
          margin: 0;
          padding-left: 20px; 
          ${lg(`
            font-size:22px;
            line-height:28px;
            padding: 10px 0 0 0; 
          `)} 
        }
      }
      .outsourcing-card-info {
        display: flex;
        align-items: center;
        padding:0 50px 0 30px;
        position: relative;
        ${lg(`
          padding:0 30px 0 20px;
        `)}
        ${md(` 
          padding:10px 0 0 0;
        `)}
          p {
            font-size: 17px;
            letter-spacing: -0.12px;
            line-height: 26px;
            margin-bottom: 0;
          }
        }
    }
  }
`
