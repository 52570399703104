import styled from 'styled-components'
import { lg, sm, md, sh, themeColor, white, black, xl } from 'config/variables'

export const BrandsLogoSecStyle = styled.section`
  padding: 110px 0;
  position: relative;
  background: #FAFBFF;
  ${sh(`
    padding: 90px 0; 
	`)}
  ${xl(`
    padding: 80px 0; 
  `)}
  ${lg(`
    padding: 60px 0; 
  `)}
  ${md(`
    padding: 40px 0; 
  `)} 
  .logo-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${lg(`
      flex-direction:column;
    `)}
  }
  .logo-content { 
    flex: 0 1 400px;
    padding-right: 40px; 
    position: relative;
    ${lg(`
      flex: 1 1 auto;
      padding-right: 0; 
      padding-bottom: 30px;
    `)} 
    h2 {
      margin: 0;
    }
    p {
      font-size: 24px;
      letter-spacing: -0.15px;
      line-height: 34px;
      margin-bottom: 30px;
      ${sh(`
        font-size: 21px;
        line-height: 30px;
        margin-bottom: 27px;
      `)}
      ${md(`
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 20px;
      `)}
      br{
        ${md(`
          display: none;
        `)}
      }
    }  
    .btn-read {
      font-size: 18px;
      line-height: 24px;
      margin-top: 23px;
      font-weight: 500;
      position: relative; 
      color: ${themeColor};
      transition: all 0.5s ease;
      display: inline-block;
      .line {
        width: 60px;
        height: 3px;
        background: #EF5366;
        border-radius: 1px;
        display: block;
        -webkit-transition: all 0.5s ease;
        transition: all 0.5s ease;
        position: absolute;
        top: -20px;
        &::before {
          content: '';
          height: 100%;
          background: #000;
          position: absolute;
          top: 0;
          left: 0;
          width: 0;
          -webkit-transition: all 0.5s ease;
          transition: all 0.5s ease;
        }
      } 
      &:hover {
        color: ${black};
        .line {
          &::before {
            width:100%;
          }
        }
      }
    }
  }
  .logoImgBlock {
    flex: 0 1 710px;
    display: flex;
    flex-wrap: wrap;  
    ${xl(`
      flex: 0 1 660px;
    `)}
    ${lg(`
      flex: 1 1 auto; 
    `)} 
    .logo-wrap {
      padding: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 176px;
      flex: 0 0 25%;
      width: 25%;
      max-width: 25%;
      border-right: 1px solid #dae3f6;
      border-bottom: 1px solid #dae3f6;
      ${lg(`
        min-height: 120px;
      `)} 
      ${sm(`
        flex: 0 0 50%;
        width: 50%;
        max-width: 50%;
        min-height: 100px;
      `)}
      &:nth-child(4n + 0) {
        border-right: 0;
        ${sm(`
          border-right: 1px solid #dae3f6;
        `)}
      }
      &:nth-child(2n + 0) {
        ${sm(`
          border-right: 0; 
        `)}
      }
      &:last-child,
      &:nth-last-child(2),
      &:nth-last-child(3),
      &:nth-last-child(4) {
        border-bottom: 0;
      }  
      &:nth-last-child(4),
      &:nth-last-child(3) {
        border-bottom: 0;
        ${sm(`
          border-bottom: 1px solid #dae3f6; 
        `)} 
      }   
      img {
        width: 100%;
        height: auto;
      }
    }
  }
`
