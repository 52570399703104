import styled from 'styled-components'
import { xl, lg, device, md, sh } from 'config/variables'

export const AwardsRecognitionSec = styled.section`
  .indicator-container{
    width: 180px;
    height: 1px;
    background: #dddddd;
    margin: 0 auto;
    position: relative;
    margin-top:40px;
    .indicator-inner{
      width: 40px;
      height: 6px;
      left: 0;
      position: absolute;
      top: -6px;
      background: #3d3d3d;
    }
    ${device(`
      display:none;
    `)}
  }
  padding: 0 0 120px;
  overflow: hidden;
  position: relative;
  &:before{
    content: '';
    width: 100%; 
    height: calc(100% + 90px);
    bottom: 0;
    left: 0;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), #f8f9ff);
    position: absolute;
    z-index: 0;
  }
  ${sh(`
    padding: 0 0 72px;
  `)}
  ${xl(`
    padding: 0px 0 60px;
  `)}
  ${lg(`
    padding: 0px 0 50px;
  `)}
  .heading-wrapper{
    position: relative;
    z-index: 9;
  }
  .awards-block-listing-wrap{
    position: relative;
    overflow: hidden; 
    ${device(`
      overflow: auto;
      -webkit-overflow-scrolling: touch;
    `)}
    .awards-block-listing {
      padding: 62px 0 60px;
      display: flex;
      padding-left: calc((100vw - 1140px)/2);
      ${md(`
        padding: 20px 0 40px;
      `)}
      .award-block-wrap{
        position: relative;
        &:nth-child(2n) {
          .award-block {
            bottom: -40px;
          }
        }
        .award-block {
          position: relative;
          bottom: 0;
          width: 100%;
          height: 100%;
          max-width: 240px;
          min-width: 240px;
          margin-right: 16px;
          margin-left: 16px;
          padding: 30px 24px;
          background-color: #fff;
          box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.1);
          transition: all 800ms cubic-bezier(0.2, 0.8, 0.2, 1);
          ${sh(`
            width: 230px;
            max-width: 230px;
          `)}
          .award-img-wrapper {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 800ms cubic-bezier(0.2, 0.8, 0.2, 1);
            img {
              max-height:130px;
            }
          }
          &:hover {
            transform: translate(0px, -5px);
            ${device(`
              transform: initial;
            `)}
          }
        }
      }
    }
  }
`
