import React from 'react'
import { graphql } from 'gatsby'
import Layout from 'components/Layout/Layout'
import SEO from 'components/Seo'
import { DashboardProps } from 'components/Props/dashboard'
import { md } from 'config/variables'
import { useLazyLoad } from 'components/hooks/useLazyload'
import { useAnimationClass } from 'components/hooks/useAnimationClass'
import { ErrorBoundary } from 'components/ErrorBoundary'
import HomeBanner from 'components/HomeBanner/HomeBanner'
import BrandsLogoSec from 'components/BrandsLogoSec/BrandsLogoSec'
import OutsourcingPartnerSec from 'components/OutsourcingPartnerSec/OutsourcingPartnerSec'
import Organizationssec from 'components/Organizationssec/Organizationssec'
import Culturecraftedsec from 'components/Culturecraftedsec/Culturecraftedsec'
import Ourperspectivesec from 'components/Ourperspectivesec/Ourperspectivesec'
import AwardsRecognition from 'components/AwardsRecognition/AwardsRecognition'
import OurServicesSec from 'components/OurServicesSec/OurServicesSec'
import styled from 'styled-components'

const HomePageStyle = styled.div`
  .stats-section {
    ${md(`
    display: none;
  `)}
  }
  .awards-recognition-sec {
    margin: 0;
    background-color: #fff;
    &:before {
      display: none;
    }
    .heading-wrapper {
      text-align: left;
      max-width: 100%;
      margin: 0;
    }
  }
`
const IndexPage = props => {
  const { location } = props
  const { showContent } = useLazyLoad('.lazy-load-div')
  useAnimationClass({ showContent })

  let imageItems = [
    props.data.blogCardOne.childImageSharp.fluid,
    props.data.blogCardTwo.childImageSharp.fluid,
    props.data.blogCardThree.childImageSharp.fluid,
    props.data.ebookCard.childImageSharp.fluid,
    props.data.brochureCard.childImageSharp.fluid,
    props.data.ebookCardTwo.childImageSharp.fluid,
    props.data.ebookCardThree.childImageSharp.fluid,
  ]
  return (
    <ErrorBoundary>
      <Layout mainClass="home" location={location}>
        <SEO
          title="Product Engineering Company | AI/ML and Data"
          description="Simform is a premier digital engineering services company specializing in Cloud/MACH architectures, Data, and AI to create digital experiences and scalable products."
          keywords={[`Simform`]}
          links={[
            {
              href: 'https://www.simform.com/',
              rel: 'canonical',
            },
          ]}
          meta={[
            {
              property: 'og:locale',
              content: 'en_US',
            },
            {
              property: 'og:type',
              content: 'website',
            },
            {
              property: 'og:site_name',
              content: 'Simform',
            },
            {
              property: 'og:url',
              content: 'https://www.simform.com',
            },
            {
              itemProp: 'name',
              content: 'Simform',
            },
            {
              property: 'og:image',
              content: 'https://www.simform.com/banner.png',
            },
            {
              name: 'twitter:card',
              content: 'summary_large_image',
            },
            {
              name: 'twitter:site',
              content: '@Simform',
            },
            {
              name: 'twitter:creator',
              content: '@Simform',
            },
            {
              name: 'twitter:image',
              content: 'https://www.simform.com/banner.png',
            },
          ]}
        />
        <HomePageStyle>
          <HomeBanner
            bannerData={DashboardProps}
            showBannerImage={true}
            {...props}
          />
          <div className="lazy-load-div">
            <OurServicesSec ourServicedata={DashboardProps} />
            <BrandsLogoSec BrandsLogoData={DashboardProps.BrandsLogoData} />
          </div>
          {showContent && (
            <>
              <Organizationssec
                OrganizationsProps={DashboardProps.OrganizationsData}
              />
              <Culturecraftedsec
                CultureCraftedProps={DashboardProps.CultureCraftedData}
              />
              <AwardsRecognition awardsRecognitiondata={DashboardProps} />
              <Ourperspectivesec
                OurperspectiveProps={DashboardProps.OurperspectiveData}
                ImageThumb={imageItems}
              />
            </>
          )}
        </HomePageStyle>
      </Layout>
    </ErrorBoundary>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    bannerImage: file(relativePath: { regex: "/home-hero-banner@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 520) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    ebookCard: file(
      relativePath: {
        regex: "/ebook-building-battle-tested-software-architecture@2x.png/"
      }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 300) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    brochureCard: file(
      relativePath: { regex: "/ebook-enterprise_kubernative_strategy@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 300) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    ebookCardTwo: file(
      relativePath: { regex: "/ebook-building-scalable-frontend@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 300) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    ebookCardThree: file(
      relativePath: { regex: "/ebook-microservices-are-transforming@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 300) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    blogCardOne: file(
      relativePath: { regex: "/ctos-guide-total-cost-of-ownership.jpg/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 450) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    blogCardTwo: file(
      relativePath: { regex: "/state-of-generative-ai-in-2024@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 450) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    blogCardThree: file(
      relativePath: { regex: "/top-service-provider-2023.jpg/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 450) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
  }
`
