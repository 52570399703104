import { EXTERNAL_LINK, isWindowDefined } from 'config/utils'
import { Link } from 'gatsby'
import React from 'react'
import Image from 'components/ImgOptimized'
import {
  OurperspectivesecStyles,
  SingleBlogCardStyle,
} from './Ourperspectivesec.styles'
import Button from 'components/Button/Button'

export const SingleBlogCard = props => {
  return (
    <>
      {!props.HorizontalCard ? (
        <SingleBlogCardStyle>
          {props.linkType !== EXTERNAL_LINK ? (
            <Link className="expertCard" to={props.cardLink}>
              <figure className="cardThumb">
                <Image
                  fluid={props.ImageThumb}
                  lazyload={{
                    enabled: true,
                    once: true,
                    offset: 1000,
                    heigth: 210,
                  }}
                />
              </figure>
              <div className="content-part">
                <h4>{props.cardTitle}</h4>
                {props.cardDetails && <p>{props.cardDetails}</p>}
                {props.blogerName && props.postDate && (
                  <div className="blog-sm-info">
                    <p className="name">{props.blogerName}</p>
                    <p className="date">{props.postDate}</p>
                  </div>
                )}
              </div>
            </Link>
          ) : (
            <a
              className="expertCard"
              target={props.openNewTab ? '_blank' : ''}
              href={`${isWindowDefined ? window.location.origin : ''}${
                props.cardLink
              }`}
            >
              <figure className="cardThumb">
                <Image
                  fluid={props.ImageThumb}
                  lazyload={{
                    enabled: true,
                    once: true,
                    offset: 1000,
                    heigth: 210,
                  }}
                />
              </figure>
              <div className="content-part">
                <h4>{props.cardTitle}</h4>
                {props.cardDetails && <p>{props.cardDetails}</p>}
                {props.blogerName && props.postDate && (
                  <div className="blog-sm-info">
                    <p className="name">{props.blogerName}</p>
                    <p className="date">{props.postDate}</p>
                  </div>
                )}
              </div>
            </a>
          )}
        </SingleBlogCardStyle>
      ) : (
        <SingleBlogCardStyle className="horizontalCard">
          <div className="expertCard">
            <figure className="cardThumb">
              <Image
                fluid={props.ImageThumb}
                lazyload={{
                  enabled: true,
                  once: true,
                  offset: 1000,
                  heigth: 210,
                }}
              />
            </figure>
            <div className="content-part">
              <h4>{props.cardTitle}</h4>
              {props.cardDetails && <p>{props.cardDetails}</p>}
              {props.HorizontalCard && (
                <div className="btn-block">
                  <Button
                    type={props.linkType}
                    buttonLink={props.cardLink}
                    buttonName={props.cardLinkText}
                  />
                </div>
              )}
            </div>
          </div>
        </SingleBlogCardStyle>
      )}
    </>
  )
}

function Ourperspectivesec(props) {
  const { OurperspectiveProps } = props
  return (
    <OurperspectivesecStyles>
      <div className="container">
        <div className="ourperspective-head">
          <h2>{OurperspectiveProps.title}</h2>
        </div>
        <div className="expertCardRow">
          {OurperspectiveProps.OurPerspectiveContent.map(
            (expertsContent, i) => {
              return (
                <SingleBlogCard
                  key={i}
                  linkType={expertsContent.linkType}
                  cardLink={expertsContent.cardLink}
                  ImageThumb={props.ImageThumb[i]}
                  cardTitle={expertsContent.cardTitle}
                  cardDetails={expertsContent.cardDetails}
                  cardLinkText={expertsContent.cardLinkText}
                  HorizontalCard={expertsContent.HorizontalCard}
                  openNewTab={expertsContent.openNewTab}
                  blogerName={expertsContent.blogerName}
                  postDate={expertsContent.postDate}
                />
              )
            }
          )}
        </div>
      </div>
    </OurperspectivesecStyles>
  )
}

export default Ourperspectivesec
