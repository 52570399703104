import styled from 'styled-components'
import {
  sh,
  md,
  sm,
  xl,
  lg,
  bodyFont,
  primaryColor,
  white,
} from 'config/variables'

export const OurServicesSecStyle = styled.section`
  padding: 110px 0;
  position: relative;
  background: ${white};
  margin: 0;
  ${sh(`
    padding: 90px 0; 
	`)}
  ${xl(`
    padding: 80px 0; 
  `)}
  ${lg(`
    padding: 60px 0; 
  `)}
  ${md(`
    padding: 40px 0; 
  `)} 
  .heading {
    h2 {
      font-size: 42px;
      margin-bottom: 17px;
      ${md(`
        font-size: 36px;
      `)}
    }
    p {
      font-size: 25px;
      line-height: 34px;
      letter-spacing: -0.15px;
      ${md(`
        font-size: 18px;
        line-height: 28px;
      `)}
    }
  }
  .container{
    .our-service-list {
      margin-top: 80px;
      display: grid;
      gap:44px;
      grid-template-columns: 1fr 1fr 1fr;
      ${lg(`
        grid-template-columns: 1fr 1fr; 
        gap:24px;
      `)}
      ${sm(`
        grid-template-columns: 1fr; 
        gap:16px;
      `)}
      ${md(`
        margin-top: 50px;
      `)} 
      .our-service-card {
        border: 1px solid #5840BA33;
        border-radius:3px;
        padding:30px 23px 30px 30px;
        display:flex;
        flex-direction: column;
        justify-content:center;
        gap:20px;
        transition: all 800ms cubic-bezier(0.2,0.8,0.2,1);
        ${lg(`
          padding:16px;
        `)}
        &:hover {
          background-color: #5840ba17;
          box-shadow: 0 11px 15px -3px rgba(0, 0, 0, 0.1), 0 9px 30px 3px rgba(0, 0, 0, 0.04), 0 20px 30px 3px rgba(0, 0, 0, 0.04);
          -webkit-transform: translateY(-5px);
          -ms-transform: translateY(-5px);
          transform: translateY(-5px);
          .service-icon {
            -webkit-transform: translateY(-11px);
            -ms-transform: translateY(-11px);
            transform: translateY(-11px);
            box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 40px;
            
          }
          .our-service-card-title {
            h3 {
              color:#5840BA;
            }
            .service-card-arrow {
              transform:rotate(45deg);
            }  
          }
        }
        .service-icon {
          width:90px;
          border-radius:50%;
          height:auto;
          transition: all 800ms cubic-bezier(0.2,0.8,0.2,1);
          ${lg(`
            width:60px;
          `)}
        }
        .our-service-card-title {
          display:flex;
          justify-content:space-between;
          gap:10px;
          align-items:center;
          h3 {
            font-family: ${bodyFont};
            font-size: 22px;
            font-weight: 600;
            line-height: 28px;
            text-align: left;
            color:${primaryColor};
            margin:0px;
            transition: all 800ms cubic-bezier(0.2,0.8,0.2,1);
            ${lg(`
            font-size: 18px; 
            line-height: 26px;
          `)}
          }
          .service-card-arrow {
            transition: all 800ms cubic-bezier(0.2,0.8,0.2,1);
          }
        }
      }
    }
  }
`
