import React from 'react'
import { CulturecraftedsecStyles } from './Culturecraftedsec.styles'
import { Link } from 'gatsby'
import { EXTERNAL_LINK, isWindowDefined } from 'config/utils'
import { images } from 'config/images'

const StarIcon = props => {
  return (
    <span className="star-icon">
      <svg
        width="16"
        height="15"
        viewBox="0 0 16 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.4416 0.626611L8.44151 0.626472C8.39554 0.547466 8.31264 0.5 8.22267 0.5V0C8.09143 0.000127325 7.96247 0.0342827 7.84837 0.0991316C7.73427 0.163981 7.63894 0.257307 7.57167 0.37L8.4416 0.626611ZM8.4416 0.626611L10.6626 4.44061L10.7735 4.63105L10.9889 4.67768L15.2989 5.61069L15.3011 5.61115C15.3445 5.62035 15.3848 5.64082 15.4178 5.67051C15.4508 5.7002 15.4754 5.73806 15.4892 5.78028C15.5029 5.8225 15.5053 5.8676 15.4961 5.91104C15.4869 5.95449 15.4665 5.99475 15.4368 6.02777L15.4358 6.02883L12.4958 9.31884L12.3488 9.48336L12.3713 9.70286L12.8181 14.0737C12.8182 14.0738 12.8182 14.0739 12.8182 14.0741C12.8225 14.118 12.8153 14.1623 12.7972 14.2026C12.779 14.243 12.7506 14.2779 12.7147 14.3039C12.6788 14.3298 12.6367 14.3459 12.5927 14.3505C12.5486 14.3551 12.5042 14.348 12.4637 14.3301L12.4611 14.3289L8.42312 12.5619L8.22196 12.4739L8.02103 12.5625L3.98903 14.3395L3.98767 14.3401C3.9472 14.358 3.90272 14.3651 3.85867 14.3605C3.81463 14.3559 3.77256 14.3398 3.73668 14.3139C3.7008 14.2879 3.67235 14.253 3.65419 14.2126C3.63609 14.1723 3.62884 14.128 3.63316 14.0841C3.63317 14.084 3.63318 14.0838 3.6332 14.0837L4.08008 9.71184L4.10247 9.49277L3.95597 9.32836L1.01097 6.02336L1.00994 6.02221C0.980347 5.9892 0.959944 5.949 0.950778 5.90562C0.941613 5.86224 0.944006 5.81722 0.95772 5.77506C0.971433 5.7329 0.995984 5.69508 1.02891 5.6654C1.06184 5.63571 1.10199 5.6152 1.14535 5.60592L1.14646 5.60568L5.45646 4.67268L5.67185 4.62605L5.78275 4.43561L8.00204 0.624556M8.4416 0.626611L8.00204 0.624556M8.00204 0.624556C8.02493 0.586863 8.05707 0.555629 8.09543 0.533827C8.13437 0.511698 8.17837 0.500043 8.22316 0.5L8.65959 0.499576L8.65975 0.499576L8.72267 0.499515V0.462932V0.462866V0.19018C8.78162 0.242621 8.83278 0.304728 8.87367 0.375L11.0947 4.189L8.00204 0.624556Z"
          fill={props.filled ? props.color : 'transparent'}
          stroke={props.color}
        />
      </svg>
    </span>
  )
}
function Culturecraftedsec(props) {
  const { CultureCraftedProps } = props
  return (
    <CulturecraftedsecStyles>
      <div className="container">
        <div className="cultureCraftedInner">
          <div className="cultureCraftedThumb">
            <img
              className="bg-image"
              src={images.cultureCraftedBgImage}
              alt={CultureCraftedProps.title}
            />
            <div className="rating-card">
              <a
                className="rating-card-logo"
                href={CultureCraftedProps.gretplaceToWork.link}
                target="_blank"
              >
                <img
                  src={images.gretplaceToWorkLogo}
                  alt={CultureCraftedProps.gretplaceToWork.title}
                />
              </a>
              <h4>{CultureCraftedProps.gretplaceToWork.title}</h4>
              {CultureCraftedProps.gretplaceToWork.reviewList.map((data, i) => {
                return (
                  <div key={i} className="review-item-row">
                    <h6 className="review-text">{data.label}</h6>
                    <p className="designation-text">
                      {data.designation}
                      <a
                        className="rating-group"
                        href={CultureCraftedProps.gretplaceToWork.link}
                        target="_blank"
                      >
                        <StarIcon filled color="#FF3234" />
                        <StarIcon filled color="#FF3234" />
                        <StarIcon filled color="#FF3234" />
                        <StarIcon filled color="#FF3234" />
                        <StarIcon filled color="#FF3234" />
                      </a>
                    </p>
                  </div>
                )
              })}
            </div>
            <div className="rating-card glassdoor-card">
              <a
                className="rating-card-logo"
                href={CultureCraftedProps.glassdoor.link}
                target="_blank"
              >
                <img
                  src={images.glassdoorLogo}
                  alt={CultureCraftedProps.glassdoor.title}
                />
              </a>
              <h4>{CultureCraftedProps.glassdoor.title}</h4>
              {CultureCraftedProps.glassdoor.reviewList.map((data, i) => {
                return (
                  <div key={i} className="review-item-row">
                    <h6 className="review-text">{data.label}</h6>
                    <p className="designation-text">
                      {data.designation}
                      <a
                        className="rating-group"
                        href={CultureCraftedProps.glassdoor.link}
                        target="_blank"
                      >
                        <StarIcon filled color="#13A048" />
                        <StarIcon filled color="#13A048" />
                        <StarIcon filled color="#13A048" />
                        <StarIcon filled color="#13A048" />
                        <StarIcon filled color="#13A048" />
                      </a>
                    </p>
                  </div>
                )
              })}
            </div>
          </div>
          <div className="cultureCraftedContent">
            <h2>{CultureCraftedProps.title}</h2>
            <p>{CultureCraftedProps.info}</p>
            {CultureCraftedProps.linkType !== EXTERNAL_LINK ? (
              <Link className="btn-read" to={CultureCraftedProps.infoLink}>
                <span className="line" />
                Learn more
              </Link>
            ) : (
              <a
                className="btn-read"
                target={CultureCraftedProps.openNewTab ? '_blank' : ''}
                href={`${isWindowDefined ? window.location.origin : ''}${
                  CultureCraftedProps.infoLink
                }`}
              >
                <span className="line" />
                Learn more
              </a>
            )}
          </div>
        </div>
      </div>
    </CulturecraftedsecStyles>
  )
}

export default Culturecraftedsec
