import styled from 'styled-components'
import { lg, sm, md, sh, themeColor, white, xl } from 'config/variables'

export const HomeBannerStyles = styled.section`
  padding-top: 166px; 
  position: relative;
  ${xl(`
    padding-top: 140px; 
  `)}
  ${lg(`
    padding-top: 90px; 
  `)}
  ${md(`
    padding-top: 90px;
  `)}
  ${sm(`padding-top: 80px`)}
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 80%;
    top: 0;
    left: 0;
    z-index: -1;
    /* background-image: linear-gradient(to top, #fff, #f0efff); */
  }
  .banner-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${lg(`
      flex-direction:column;
    `)}
  }
  .banner-content { 
    flex: 0 1 520px;
    padding-right:80px;
    ${xl(`
      padding-right:30px;
    `)}
    ${lg(`
      padding-right:0;
      flex: 1 1 auto;
      margin-bottom:30px;
      text-align:center;
    `)}
    h1 {
      margin: 0 0 16px;
    }
    .heading-red {
      background-image: linear-gradient(100deg, #3d3d3d, #ff4d63);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    p {
      font-size: 21px;
      letter-spacing: -0.15px;
      line-height: 28px;
      margin-bottom: 30px;
      ${sh(`
        font-size: 21px;
        line-height: 30px;
        margin-bottom: 27px;
      `)}
      ${md(`
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 20px;
      `)}
      br{
        ${md(`
          display: none;
        `)}
      }
    }
    .button {
      letter-spacing: 0.025em;
      padding: 9px 20px 11px;
      background: ${themeColor};
      color: ${white}; 
      &:before,
      &:after {
        background: ${white};
      }
      &:hover {
        color: ${themeColor};
      }
    }
  }
  .banner-img {
    flex: 0 1 520px;  
    ${sh(`
      max-width: 70%;
      margin: 0 auto;
      margin-top: 30px;
		`)}
    ${sm('margin: 35px 0 20px')}
    ${lg(`
      width:100%;
      flex: 1 1 auto; 
    `)}
  }
`
