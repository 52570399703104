import styled from 'styled-components'
import {
  lg,
  sm,
  md,
  themeColor,
  device,
  primaryColor,
  xl,
  white,
  sh,
} from 'config/variables'

export const OurperspectivesecStyles = styled.section`
  background-color: #fafbff;
  padding: 110px 0;
  margin: 0px;
  ${sh(`
    padding: 90px 0; 
	`)}
  ${xl(`
    padding: 80px 0; 
  `)}
  ${lg(`
    padding: 60px 0; 
  `)}
  ${md(`
    padding: 40px 0; 
  `)} 
  .ourperspective-head {
    margin-bottom: 40px;
      ${md(`
        margin-bottom: 20px; 
      `)}
      h2 {
        margin-bottom: 0;
      }
  }
  .expertCardRow {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
    ${lg(`
       flex-wrap: nowrap;
       overflow:auto;
    `)}
  }
`

export const SingleBlogCardStyle = styled.div`
  width: 33.33%;
  min-width: 33.33%;
  flex: 0 1 33.33%;
  padding: 15px;
  display: flex;
  flex-direction: column;  
  ${lg(`
    width: 300px;
    min-width: 300px;
    flex: 0 1 300px;
  `)}
  &.horizontalCard {
    width: 50%;
    min-width: 50%;
    flex: 0 1 50%; 
    ${lg(`
      width: 500px;
      min-width: 500px;
      flex: 0 1 500px;
    `)}
     
    .cardThumb {
      flex: 0 0 200px;
      max-width: 200px;
      width: 200px;
      filter: drop-shadow(0 4px 5px rgba(0, 0, 0, 0.3));
      ${sm(`
        flex: 0 0 160px;
        max-width: 160px;
        width: 160px;
      `)}
      img {
        display: block;
      }
    }
    .expertCard {
      flex-direction: row;
      padding: 20px 15px;
      ${xl(`
        padding: 15px;
      `)}
      ${lg(`
        padding: 15px;
      `)} 
    }
    .content-part {
      padding: 0 0 0 15px;
      ${sm(`
        padding: 0 0 0 10px;
      `)}
       p { 
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical; 
      } 
    } 
  }
  .expertCard {
    height: 100%;
    display: flex;
    flex-direction: column;
    background: ${white};
    box-shadow: 0 1px 18px 0 rgba(0, 0, 0, 0.05),
      0 3px 5px -1px rgba(0, 0, 0, 0.07);
    transition: all 0.4s ease;
    &:hover {
      box-shadow: 4px 4px 26px 5px rgba(0, 0, 0, 0.05),
        6px 8px 26px 0px rgba(0, 0, 0, 0.02);
      transform: translate(0px, -10px);
      ${device(`
        box-shadow: 0 1px 18px 0 rgba(0, 0, 0, 0.05), 0 3px 5px -1px rgba(0, 0, 0, 0.07);
        transform: initial;
      `)} 
    }
  }
  figure {
    width: 100%;
    display: block; 
  }
  .content-part {
    padding: 15px 20px;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    ${xl(`
        padding: 15px;
    `)}
    ${lg(`
        padding: 15px;
    `)}
    ${sm(`
       padding: 15px 10px;
    `)}
    h4 {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.17px;
      font-weight: 500;
      margin-bottom: 10px;
      transition: all 0.3s ease;
      color: ${primaryColor}; 
    }
    p {
      font-size: 14px;
      line-height: 21px;
      color: ${primaryColor};
      width:100%;
      overflow: hidden;
      letter-spacing: -0.15px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical; 
    } 
    .blog-sm-info {
      display: flex;
      justify-content: space-between;
      margin-top: auto;
      .name {
        font-weight: 400;
        font-size: 12px;
        line-height: 13px;
        color: ${themeColor};
        margin: 0;
        text-align: left;
      }
      .date {
        font-weight: 400;
        font-size: 12px;
        line-height: 13px;
        color: rgba(61,61,61,.7);
        margin: 0;  
        text-align: right;
      }
    }
    .btn-block {
      margin-top: auto;
    }
  }
`
