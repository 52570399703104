import React, { Component } from 'react'
import { OurServicesSecStyle } from './OurServicesSec.styles'
import SingleService from 'components/SingleService/SingleService'
import NavLink from 'components/NavLink/NavLink'
import { images } from 'config/images'

class OurServicesSec extends Component {
  render() {
    const { ourServiceProps } = this.props.ourServicedata
    return (
      <OurServicesSecStyle>
        <div className="container">
          <div className="heading">
            <h2>{ourServiceProps.heading}</h2>
            <p>{ourServiceProps.para}</p>
          </div>
          <div className="our-service-list">
            {ourServiceProps.serviceContents.map((serviceContentProp, i) => {
              return (
                <NavLink
                  key={i}
                  linkType={serviceContentProp.linkType}
                  className="our-service-card"
                  navLink={serviceContentProp.serviceLink}
                >
                  <img
                    className="service-icon"
                    src={serviceContentProp.serviceIcon}
                    alt={serviceContentProp.serviceHeading}
                  />
                  <div className="our-service-card-title">
                    <h3>{serviceContentProp.serviceHeading}</h3>
                    <img
                      className="service-card-arrow"
                      src={images.serviceArrowIcon}
                      alt="arrow icon"
                    />
                  </div>
                </NavLink>
              )
            })}
          </div>
        </div>
      </OurServicesSecStyle>
    )
  }
}

export default OurServicesSec
