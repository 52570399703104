import React from 'react'
import Button from 'components/Button/Button'
import Image from 'components/ImgOptimized'
import { BrandsLogoSecStyle } from './BrandsLogoSec.styles'

function BrandsLogoSec(props) {
  const { BrandsLogoData } = props
  return (
    <BrandsLogoSecStyle>
      <div className="container">
        <div className="logo-inner">
          <div className="logo-content">
            <h2>{BrandsLogoData.title}</h2>
            {BrandsLogoData.info && <p>{BrandsLogoData.info}</p>}
            {/* <a href="#" className="btn-read">
              <span className="line" />
              Read more
            </a> */}
          </div>
          <div className="logoImgBlock">
            {BrandsLogoData.clientLogos.map((clientLogo, i) => {
              return (
                <div key={i} className="logo-wrap">
                  <a
                    href={clientLogo.clientLogoLink}
                    rel="nofollow noopener noreferrer"
                    target="_blank"
                    className="logo-block"
                  >
                    <img
                      className="hvr-logo"
                      src={clientLogo.clientLogosrcg}
                      alt={clientLogo.clientLogoAlt}
                    />
                  </a>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </BrandsLogoSecStyle>
  )
}

export default BrandsLogoSec
