import React, { useEffect, useState } from 'react'
import { OrganizationssecStyles } from './Organizationssec.styles'
import loadable from '@loadable/component'
import Newcasestudiescard from 'components/Newcasestudiescard/Newcasestudiescard'
import { EXTERNAL_LINK, isWindowDefined } from 'config/utils'
import { Link } from 'gatsby'

const ReactSlick = loadable.lib(() => import('react-slick'))

function Organizationssec(props) {
  const { OrganizationsProps } = props
  const [sliderIndex, setSliderIndex] = useState('0')

  function beforeChange(prev, next) {
    setSliderIndex(next)
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 800,
    slidesToShow: 2,
    slidesToScroll: 1,
    rows: 2,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    centerPadding: 0,
    beforeChange: beforeChange,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          centerMode: false,
          variableWidth: false,
          centerPadding: 0,
        },
      },
    ],
  }

  function SamplePrevArrow(props) {
    const { onClick } = props
    return (
      <button
        className="slick-arrow slick-prev"
        disabled={sliderIndex === 0}
        onClick={onClick}
      >
        <svg width="32" height="25" viewBox="0 0 32 25">
          <path
            fill="#3D3D3D"
            fillRule="evenodd"
            d="M0 12a1.22 1.22 0 0 1 0-.13v-.11a1.25 1.25 0 0 1 0-.13l.05-.1.07-.12.07-.09.08-.1L11 .39a1.3 1.3 0 0 1 1.82 0 1.25 1.25 0 0 1 0 1.79l-8.43 8.56h26.32a1.3 1.3 0 0 1 0 2.59H4.29l8.55 8.55a1.25 1.25 0 0 1 0 1.79 1.3 1.3 0 0 1-1.82 0L.37 13l-.08-.09-.08-.09-.06-.11-.06-.11a1.18 1.18 0 0 1 0-.12 1.22 1.22 0 0 1 0-.12 1.16 1.16 0 0 1 0-.12 1.2 1.2 0 0 1 0-.13L0 12z"
          />
        </svg>
      </button>
    )
  }

  function SampleNextArrow(props) {
    const { onClick } = props
    return (
      <button
        className="slick-arrow slick-next"
        disabled={sliderIndex === 5}
        onClick={onClick}
      >
        <svg width="32" height="25" viewBox="0 0 32 25">
          <path
            fill="#3D3D3D"
            fillRule="evenodd"
            d="M32 12a1.22 1.22 0 0 0 0-.13v-.11a1.25 1.25 0 0 0 0-.13l-.05-.1-.07-.12-.07-.09-.08-.1L21 .39a1.3 1.3 0 0 0-1.82 0 1.25 1.25 0 0 0 0 1.79l8.43 8.56H1.29a1.3 1.3 0 0 0 0 2.59h26.42l-8.55 8.55a1.25 1.25 0 0 0 0 1.79 1.3 1.3 0 0 0 1.82 0L31.63 13l.08-.09.08-.09.06-.11.06-.11a1.18 1.18 0 0 0 0-.12 1.22 1.22 0 0 0 0-.12 1.16 1.16 0 0 0 0-.12 1.2 1.2 0 0 0 0-.13L32 12z"
          />
        </svg>
      </button>
    )
  }
  return (
    <OrganizationssecStyles>
      <div className="container">
        <div className="organizations-head">
          <h2>{OrganizationsProps.title}</h2>
          <p>{OrganizationsProps.info}</p>
        </div>
        <div className="organizations-case-list">
          <ReactSlick>
            {({ default: Slider }) => (
              <Slider {...settings}>
                {OrganizationsProps.CaseStudyList.map((CaseStudy, i) => {
                  return (
                    <React.Fragment key={i}>
                      <a
                        className="case-card-link"
                        href={`${
                          isWindowDefined ? window.location.origin : ''
                        }${CaseStudy.caseLink}`}
                      >
                        <Newcasestudiescard {...CaseStudy} />
                      </a>
                    </React.Fragment>
                  )
                })}
              </Slider>
            )}
          </ReactSlick>
        </div>
      </div>
    </OrganizationssecStyles>
  )
}

export default Organizationssec
