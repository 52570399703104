import styled from 'styled-components'
import { lg, sm, md, sh, xl, xll, device, primaryColor } from 'config/variables'

export const OrganizationssecStyles = styled.section`
.organizations-head {   
  margin-bottom: 36px;  
  ${md(`
    margin-bottom: 30px;
  `)}
  p {
    font-size: 21px;
    letter-spacing: -0.15px;
    line-height: 28px;
    margin-bottom: 30px;
    ${sh(`
      font-size: 20px;
      line-height: 26px;
      margin-bottom: 27px;
    `)}
    ${md(`
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 20px;
    `)}
    br{
      ${md(`
        display: none;
      `)}
    }
  }   
}
.case-card-link {
  text-decoration: none;
  color: ${primaryColor};
}
.organizations-case-list { 
  .slick-slider {
    ${xll(`
      padding:0 15px;
    `)} 
     ${xl(`
      padding:0 15px;
    `)} 
    ${lg(`
      padding:0 15px;
    `)} 
    ${sm(`
      padding:0 ;
    `)} 
    .slick-list {
      padding: 0 0 20px !important;   
      margin: 0 -15px;
      ${device(`
        padding: 0 !important;
      `)}
      ${xl(`
        margin: 0 -8px;
      `)} 
      ${lg(`
        margin: 0 -8px;
      `)} 
       ${sm(`
        padding: 0 0 10px !important;
      `)} 
      .slick-track {
        display: flex;
        .slick-slide { 
          padding: 0 15px;
          ${xl(`
            padding: 0 8px;
          `)} 
          ${lg(`
            padding: 0 8px;
          `)} 
          > div {
            padding: 15px 0;
            margin: 0 auto;
            width: 100%;
            ${device(`
              padding: 10px 0;
            `)}
          }
        }
      }
    }
    .slick-arrow {
      width: 32px;
      position: absolute;
      padding: 0;
      top: 50%;
      z-index: 9;
      height: 24px;
      background-color: transparent;
      margin-top: -12px;
      transition: all 0.3s ease;
      outline: none;
      opacity: 1;
      border: 0;
      cursor: pointer;
      &[disabled] {
        display: none;
      }
      svg{
        width: 100%;
      }
      ${sm(`
        width: 25px;
      `)}
      &:hover {
        opacity: 0.8;
      }
      &.slick-prev {
        left: -60px;
        ${xll(`
          left: -32px;
        `)}
        ${xl(`
          left: -32px;
        `)} 
        ${lg(`
          left: -32px;
        `)} 
        ${md(`
          left: -22px;
        `)}
        ${sm(`
          top: inherit;
          margin: 0;
          bottom: -20px;
          left: calc(50% - 35px);
        `)}
      }
      &.slick-next {
        right: -60px;
        ${xll(`
          right: -32px;
        `)} 
        ${xl(`
          right: -32px;
        `)} 
        ${lg(`
          right: -32px;
        `)}
        ${md(`
          right: -22px;
        `)}
        ${sm(`
          top: inherit;
          margin: 0;
          bottom: -20px;
          right: calc(50% - 35px);
        `)}
      }
    }
  }
}
`
